import React from "react";
import { useStaticQuery } from "gatsby";
import RichTextRenderer from "../utils/richTextRenderer";

import PageHeader from "../components/pageHeader";
import Layout from "../components/layout";
import SEO from "../components/seo";

const CyfhRegistries = ({ location }) => {
  const { cyfhRegistries } = useStaticQuery(
    graphql`
      query {
        cyfhRegistries: contentfulCyFhRegistriesPage {
          content {
            json
          }
        }
      }
    `
  );

  return (
    <Layout pageTitle="cyfh-registries">
      <SEO pageTitle="Μητρώα καταγραφής ασθενών" />
      <PageHeader
        pageTitle="ΚΥΠΡΙΑΚΟ ΕΘΝΙΚΟ ΜΗΤΡΩΟ ΚΑΤΑΓΡΑΦΗΣ ΑΣΘΕΝΩΝ ΜΕ FH"
        pageDescription="Iσχυρό εργαλείο για την καταγραφή και παρακολούθηση της νόσου"
        page="Μητρώα καταγραφής ασθενών"
        location={location}
        isCyFH={true}
      />

      <div className="page-wrapper">
        <RichTextRenderer richTextJson={cyfhRegistries.content.json} />
      </div>
    </Layout>
  );
};

export default CyfhRegistries;
